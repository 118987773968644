<template>
  <div v-if="false" class="text-center" />
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
const PHONE_TABLE_SIZE = '(min-width: 769px)';
export default {
  computed: {
    ...mapGetters({
      isMobile: 'getIsMobileDensity',
    }),
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    ...mapMutations(['setIsNavOpen', 'setIsMobileDensity']),
    handleResize() {
      const mq = window.matchMedia(PHONE_TABLE_SIZE);
      if (mq.matches) {
        this.setIsNavOpen(true);
        this.setIsMobileDensity(false);
      } else {
        this.setIsMobileDensity(true);
      }
    },
  },
};
</script>
