<template>
  <div>
    <navigation ref="nav"        
     @coordinateAppointment="coordinateAppointmentHandler"/>
    <window-resize />

    <PortalTarget name="sub-header" />

    <main class="page-content" :class="{ 'mobile-responsive': isMobile }">
      <router-view />
    </main>
  </div>
</template>

<script>
import Navigation from './Navigation';
import store from '@/store/index';
import WindowResize from './components/WindowResize';
import alert from '@/mixins/alert.mixin';
import { getAppointmentSystemUrl } from '@/api/code.api';

import '@/assets/css/all.css';
import '@/assets/css/solid.css';
import '@/assets/css/style.css';
import '@/assets/css/mobile.css';

export default {
  components: {
    navigation: Navigation,
    WindowResize,
  },
  mixins: [alert],
  data() {
    return {
      windowWidth: 0,
      sessionExpired: false,
      appointmentSystemUrl: ''      
    };
  },
  computed: {
    isMobile() {
      return store.getters.getIsMobileDensity;
    },
  },
  watch: {
    sessionExpired(newValue) {
      if (newValue) {
        this.showCloseAlert();
      }
    },
  },
  methods: {
    async showCloseAlert() {
      this.ShowAlert(
        'La sesión ha expirado',
        'Debe iniciar sesión nuevamente para continuar.',
        'error',
        async () => {
          await store.dispatch('$_user/logout');
        }
      );
    },
    onCloseSidebar() {
      if (store.state.isMobileDensity) {
        store.commit('toggleNav');
      }
    },
    activateActivityTracker() {
      window.addEventListener('mousemove', this.userActivityThrottler);
      window.addEventListener('scroll', this.userActivityThrottler);
      window.addEventListener('keydown', this.userActivityThrottler);
      window.addEventListener('resize', this.userActivityThrottler);
      window.addEventListener('beforeunload', this.deactivateActivityTracker);
    },
    deActivateActivityTracker() {
      window.removeEventListener('mousemove', this.userActivityThrottler);
      window.removeEventListener('scroll', this.userActivityThrottler);
      window.removeEventListener('keydown', this.userActivityThrottler);
      window.removeEventListener('resize', this.userActivityThrottler);
      window.removeEventListener(
        'beforeunload',
        this.deactivateActivityTracker
      );
    },
    userActivityThrottler() {
      if (!this.sessionExpired) {
        clearTimeout(this.userActivityThrottlerTimeout);
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.handleSessionExpiration();
          clearTimeout(this.userActivityThrottlerTimeout);
        }, process.env.VUE_APP_SESSION_TIMEOUT * 60000);
      }
    },
    async handleSessionExpiration() {
      this.sessionExpired = true;
      await store.commit('$_user/SET_USER_LOGOUT');
    },
    coordinateAppointmentHandler(){            
      window.open(this.appointmentSystemUrl);    
    }
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.userActivityThrottler);
    window.removeEventListener('scroll', this.userActivityThrottler);
    window.removeEventListener('keydown', this.userActivityThrottler);
    window.removeEventListener('resize', this.userActivityThrottler);
    clearTimeout(this.userActivityThrottlerTimeout);
  },
  async mounted() {
    this.activateActivityTracker();
    if (store.state.$_user.isLoggingOut) {
      this.showCloseAlert();
    }
    window.location.hash = '';

    const { data } = await getAppointmentSystemUrl();
    this.appointmentSystemUrl = data;
  },
};
</script>

<style scope>
#main {
  margin-bottom: 30px;
}

.blured-body {
  left: 119px;
  top: 100px;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 11;
}
.blured-body {
  backdrop-filter: blur(8px);
}
</style>
