import { http } from '@/core/api/';

export const changeUserPassword = (args) => {
  return http.post('/api/account/changepassword/', args);
};

export const GetReferralAdmissionDetail = (studentServiceId, args) => {
  return http.get(
    '/api/thrpyadmission/ReferralAdmissionDetail/' + studentServiceId,
    args
  );
};

export const GetReferralAdmissionHistory = (id, args) => {
  return http.get('/api/thrpyadmission/ReferralAdmissionHistory/' + id, args);
};

export const GetStudentDisciplinesForRemedy = (studentSieId, args) => {
  return http.get(
    '/api/thrpyadmission/StudentDisciplinesForRemedy/' + studentSieId,
    args
  );
};
