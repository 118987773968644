1
<template>
  <div>
    <div id="wrapper" class="d-flex">
      <div id="page-content-wrapper">
        <nav
          class="
            navbar navbar-expand-lg navbar-light
            bg-na-principal
            border-bottom
            fixed-top
            custom-navbar
          "
          :class="{ 'mobile-responsive': isMobile }"
        >
          <div class="sidebar-heading">
            <img
              alt="logo"
              height="63px"
              :src="logoImgUrl"              
              width="190px"
            />
          </div>
          <div
            id="navbarSupportedContent"
            class="collapse navbar-collapse show float-right left"
          >
            <ul
              class="navbar-nav mr-auto mt-2 mt-lg-0 d-flex align-items-center"
            >
              <li :class="`nav-item mr-2  ${isMobile ? `` : `custom-width`}`">
                <action-button
                  v-if="canGoToTurnos"
                  i="fas fa-external-link-alt"
                  text="Coordinar Cita en Turnos DEPR"
                  variant="success"
                  @click="$emit('coordinateAppointment')"
                />
              </li>
              <li class="nav-item mr-2">
                <a
                  id="action-item-forms"
                  class="cursor-pointer"
                  role="button"
                  @click="$router.push({ name: 'home' }).catch(() => {})"
                >
                  <span class="form_button rounded-circle border-green">
                    <em class="fas fa-home form_icon iconNav2" />
                  </span>
                </a>
              </li>
              <li
                id="action-item-notification"
                class="nav-item dropdown no-arrow mx-1"
                style="display: contents"
              >
                <router-link
                  class="nav-icon-link cursor-pointer mr-2"
                  :to="{ name: 'message' }"
                >
                  <li class="nav-item">
                    <span class="avatar avatar-sm rounded-circle">
                      <em class="fas fa-envelope iconNav2" />
                    </span>
                    <span class="rounded-circle alert-indicator">
                      {{ messageCount }}
                    </span>
                  </li>
                </router-link>
                <a
                  id="action-item-help"
                  aria-expanded="false"
                  aria-haspopup="true"
                  class="cursor-pointer mr-2 nav-icon-link"
                  data-toggle="dropdown"
                  role="button"
                >
                  <li class="nav-item">
                    <span class="avatar avatar-sm rounded-circle">
                      <em class="fal fa-question-circle iconNav1" />
                    </span>
                  </li>
                </a>
                <div
                  class="
                    dropdown-list dropdown-menu dropdown-menu-custom
                    custom-right
                    mt-3
                    border-0
                    shadow
                  "
                >
                  <a
                    class="
                      dropdown-item
                      d-flex
                      align-items-center
                      cursor-pointer
                    "
                  >
                    <div class="mr-3 div-help">
                      <em class="fas fa-book" />
                    </div>
                    <div class="help-text">
                      <span class="text-hepl-grid">
                        <strong>Manual</strong>
                      </span>
                    </div>
                  </a>
                  <div class="my-2" />
                  <a class="dropdown-item d-flex align-items-center" href="#">
                    <div class="mr-3 div-help mb-1">
                      <em class="fas fa-video" />
                    </div>
                    <div class="help-text mb-1">
                      <span class="text-hepl-grid">
                        <strong>Videos</strong>
                      </span>
                    </div>
                  </a>
                  <a class="dropdown-item support-table shadow-top">
                    <div class="mr-3 div-help">
                      <span class="text-hepl-grid">
                        <strong>Mesa de Apoyo</strong>
                      </span>
                    </div>
                  </a>
                  <a
                    class="
                      dropdown-item
                      d-flex
                      align-items-center
                      support-information
                    "
                    href="#"
                  >
                    <div class="mr-3 help-information mt-2">
                      <em class="fas fa-phone-square-alt" />
                    </div>
                    <div class="ml-1 mt-2">
                      <span class="information-text">787-773-6147</span>
                    </div>
                  </a>
                  <a
                    class="
                      dropdown-item
                      d-flex
                      align-items-center
                      support-information
                    "
                    href="mailto:padresee@de.pr.gov"
                  >
                    <div class="help-information ml-0 mt-0">
                      <em class="fas fa-envelope" />
                    </div>
                    <div class="support-label mt-0">
                      <span>padresee@de.pr.gov</span>
                    </div>
                  </a>
                </div>
              </li>
              <CustomUserInfo
                class="user-info__extended custom-right"
                :last-login-date="userCard.lastLogin"
                :row-actions="userCard.rowActions"
                :row-elements="userCard.rowElements"
              />
            </ul>
          </div>
        </nav>
        <div class="container-fluid student-container" />
      </div>
      <modal-password
        :show="showChangePasswordModal"
        @on-close="showChangePasswordModal = false"
        @submit="handleChangePassword"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/store/index';
import CustomUserInfo from 'CustomUserInfoPanel';
import ModalPassword from '@quisit/mipe-modal-password';
import { changeUserPassword } from '@/modules/user/api';
import loading from '@quisit/mipe-utils/mixins/loading/index';
import alert from '@quisit/mipe-utils/mixins/alert.mixin';
import ActionButton from 'ActionButton';
import messageApi from '@/services/api/message.api';

import logoUrl from "../../assets/img/logo.svg";
import logoTrainingUrl from "../../assets/img/logo-training.svg"

export default {
  components: {
    CustomUserInfo,
    ModalPassword,
    ActionButton,
  },
  mixins: [alert, loading],
  data() {
    return {
      showChangePasswordModal: false,
      isLoading: false,
      loadingColor: '#41b883',
      loadingBackground: '#ffffff',
    };
  },
  methods: {
    async logoff() {
      await store.dispatch('$_user/logout');
    },
    async getMessageCount() {
      var { data } = await messageApi.getMessageCount();
      this.$store.commit('setMessageCount', data);
    },
    async handleChangePassword(e) {
      this.ShowWait();

      let payload = {
        username: this.userProfile.name,
        currentPassword: e.currentPassword,
        confirmPassword: e.confirmPassword,
        newPassword: e.newPassword,
      };
      let vm = this;

      changeUserPassword(payload)
        .then(({ data }) => {
          if (!data.error) {
            this.showChangePasswordModal = false;

            vm.ShowToast(
              'Proceso exitoso.',
              'La contraseña ha sido cambiada exitosamente.',
              'success',
              5000
            );

            return;
          }
        })
        .catch(function(errors) {
          vm.renameKeyErrors(errors.response.data.errors);
          vm.$refs.formValidator.setErrors(errors.response.data.errors);
        })
        .finally(() => {
          this.HideWait();
        });
    },
  },
  computed: {
    messageCount() {
      return store.getters.getMessageCount;
    },
    canGoToTurnos() {
      return this.userHasPermissions(
        'parents.general.coordinateappointmentdeprsystem'
      );
    },
    logoImgUrl(){
      if(this.isTrainingEnv())
        return logoTrainingUrl;        
      return logoUrl;
    },
    ...mapGetters({
      isMobile: 'getIsMobileDensity',
      user: '$_user/fullProfile',
    }),
    userCard() {
      let user = this.user;
      return user
        ? {
            rowElements: [
              {
                text: user.role,
                icon: 'fas fa-key',
                colorIcon: 'orange',
                color: 'none',
                withBorder: false,
              },
              {
                text: user.name,
                icon: 'fas fa-user',
                colorIcon: 'orange',
                color: 'none',
                withBorder: false,
              },
              {
                text: user.email || '-',
                icon: 'fas fa-envelope',
                colorIcon: 'orange',
                color: 'none',
                withBorder: false,
              },
            ],
            rowActions: [
              {
                text: 'Mi Perfil',
                icon: 'fas fa-address-card',
                colorIcon: 'green',
                color: 'green',
                withBorder: true,
                click: () => {
                  this.$router.push({ name: 'profile' });
                },
              },
              {
                text: 'Cambiar Contraseña',
                icon: 'fas fa-repeat-alt',
                colorIcon: 'green',
                color: 'green',
                withBorder: true,
                click: () => {
                  this.showChangePasswordModal = true;
                },
              },
              {
                text: 'Salir',
                icon: 'fas fa-sign-out-alt',
                colorIcon: 'green',
                color: 'green',
                withBorder: true,
                click: this.logoff,
              },
            ],
            lastLogin: user.lastLoginDate,
          }
        : {};
    },
  },
  async mounted() {
    await this.getMessageCount();
  },
};
</script>

<style scoped lang="scss">
.custom-width {
  width: 463px;
  position: relative;
  right: 53px;
}
.student-container {
  margin-top: 65px;
}
.div-help {
  margin-left: -10px;
  color: #ff7058;
}
.help-text {
  margin-left: -10px;
}
.help-information {
  margin-left: -10px !important;
}
.information-text {
  margin-left: -7px;
  font-family: 'Lato';
  font-size: 12px;
}
.support-table {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  opacity: 1;
}
.support-information {
  text-align: left;
  font: Bold 16px/22px Lato;
  letter-spacing: 0;
  color: #20a874 !important;
  opacity: 1;
}
.support-label {
  margin-left: 11px !important;
  text-decoration: underline;
  font-family: 'Lato';
  font-size: 13px;
}
.shadow-top {
  -moz-box-shadow: inset 0 10px 10px -10px grey;
  -webkit-box-shadow: inset 0 10px 10px -10px grey;
  box-shadow: inset 0 10px 10px -10px grey;
}
.alert-indicator {
  position: absolute;
  top: 27px;
  right: 246px;
  background: #fd3737 0% 0% no-repeat padding-box;
  color: #fff;
  width: 15px;
  height: 15px;
  opacity: 1;
  line-height: 15px;
  text-align: center;
  font-size: 10px;
}

.dropdown-menu-custom {
  top: 95% !important;
}

.form_button {
  font-size: 1rem;
  display: inline-flex;
  width: 48px;
  height: 48px;
  color: #fff;
  border-radius: 50%;
  border: #f8b539 1px solid;
  align-items: center;
  justify-content: center;
}

.form_icon {
  color: #f8b539;
  font-size: 20px;
}

.user-info__extended ::v-deep > .user-panel > .dropdown-menu {
  top: 5rem;
  min-width: 15rem;
}

@media screen and (min-width: 767px) and (max-width: 991px) {
  .user-info__extended {
    margin-right: 3.64rem;
  }

  .user-info__extended ::v-deep > .user-panel > .dropdown-menu {
    top: 6rem;
    min-width: 15rem;
  }
}
@media only screen and (max-width: 993px) {
  .alert-indicator {
    position: absolute;
    top: 33px;
    right: 156px;
    background: #fd3737 0% 0% no-repeat padding-box;
    color: #fff;
    width: 15px;
    height: 15px;
    opacity: 1;
    line-height: 15px;
    text-align: center;
    font-size: 10px;
  }
}
.user-info__extended ::v-deep {
  @media only screen and (max-width: 768px) {
    .dropdown-menu-custom {
      left: -34px !important;
      margin-top: -8px !important;
    }
  }
}

.custom-right {
  right: 0 !important;
  left: auto !important;
}

.nav-icon-link:hover {
  text-decoration: none;
}
</style>
